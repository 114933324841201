import React, { useContext, useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { __deleteApiData, __getApiData, __postApiData } from "constants/apis"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import { Link } from "react-router-dom"

const ActiveLoan = props => {
  const [state, setState] = useState({
    leads: [],
    isLoading: false,
    isDelete: false,
    delete_id: null,
  })
  const { leads, isLoading, isDelete, delete_id } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: "LoanName",
        accessor: "loan_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: <div className="text-center"> LoanAmount</div>,
        accessor: "loan_amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {" "}
              {cellProps.value.toFixed(2) || "0"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center"> Loan Duration</div>,
        accessor: "duration_months",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{cellProps.value || "0"} Months</div>
          )
        },
      },
      {
        Header: <div className="text-center"> Rate Type</div>,
        accessor: "rate_type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"} </div>
        },
      },
      {
        Header: <div className="text-center"> ROI</div>,
        accessor: "intrest_rate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {cellProps.value.toFixed(2) || "0"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center"> LoanDate</div>,
        accessor: "issue_date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> TotalInterest</div>,
        accessor: "total_intrest_amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {Number(cellProps.value)?.toFixed(2) || "0"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center"> PFees</div>,
        accessor: "process_fee",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {Number(cellProps.value)?.toFixed(2) || "0.00"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center"> TaxAmt</div>,
        accessor: "tax_amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {Number(cellProps.value)?.toFixed(2) || "0.00"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center"> InstAmt</div>,
        accessor: "installment_amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {Number(cellProps.value)?.toFixed(2) || "0.00"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center"> FirstInstDate</div>,
        accessor: "first_installment_date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> MaturityDate</div>,
        accessor: "maturity_date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center">Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },
      {
        Header: <div className="text-center"> Remark</div>,
        accessor: "loan_remark",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Action</div>,
        accessor: "_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                <li>
                  <Link
                    className="btn btn-sm btn-soft-primary"
                    to={`/loan-installment?loan=${cellProps.value}`}
                  >
                    View Ins.
                  </Link>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )
  const __handleGetUsers = search => {
    __postApiData(`/g_approved_loan`, {})
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data,
            isLoading: false,
          })
        }
        return updateState({
          leads: [],
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          leads: [],
          isLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ leads: [], isLoading: true })
    __handleGetUsers("")
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={leads}
                customPageSize={30}
                classNameHeader="dark"
                isGlobalFilter
                isNextPrvButtonsEnabled={leads?.length == 0 ? false : true}
              />
              {leads?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No Report Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ActiveLoan)
