import React from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  Progress,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"
import { Link } from "react-router-dom"
const Dashboard = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className="rounded-4 p-4">
            <CardBody>
              <Col md={5}>
                <div className="d-flex">
                  <CardTitle className="bg-light float-start  p-3 px-5">
                    Loan Application
                  </CardTitle>
                </div>
                <Progress className="my-3" color="info" value="60" />
                <p className="my-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                  magnam voluptatum quod eum praesentium iusto minima maiores
                  laborum dicta facere! Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Id magnam voluptatum quod eum praesentium
                  iusto minima maiores laborum dicta facere!
                </p>

                <Link to={"/loan-request"} className="btn btn-danger">
                  Apply Now
                </Link>
              </Col>
            </CardBody>
          </Card>

          <Row className="">
            <Col md="6">
              <Card className=" bg-warning p-2 rounded-4 ">
                <CardBody className="text-white fs-4 ">
                  <i className="bx bx-support me-2 "></i>
                  <span>Support</span>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="bg-warning p-2 rounded-4 ">
                <CardBody className="text-white fs-4">
                  <i className="bx bx-money me-2 "></i>
                  Repay
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
