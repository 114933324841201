import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer bg-white p-3 ">
        <Container fluid={true}>
          <div className="d-flex justify-content-around">
            <Link to={"/"} className="d-flex flex-column text-primary">
              <i className="bx bx-home-circle mx-auto fs-2 fw-medium"></i>
              <h6 className="fw-medium">Home</h6>
            </Link>
            <Link to={"/my-account"} className="d-flex flex-column">
              <i className="bx bx-user-circle mx-auto fs-2 "></i>

              <h6>Profile</h6>
            </Link>
            <div className="d-flex flex-column">
              <i className="bx bx-menu-alt-right mx-auto fs-2"></i>

              <h6>Status</h6>
            </div>
          </div>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
